export default {
  general: {
    username: 'Benutzername',
    password: 'Passwort',
    newpass: 'Neues Passwort',
    currentpass: 'Aktuelles Passwort',
    c_password: 'Passwort bestätigen',
    c_newpass: 'Neues Passwort bestätigen',
    email: 'Email Addresse',
    language: 'Sprache',
    subject: 'Betreff',
    message: 'Nachricht',
    filtername: 'Filtername',
    placeholders: {
      username: 'Benutzername eingeben',
      password: 'Passwort eingeben',
      newpass: 'Neues Passwort eingeben',
      currentpass: 'Aktuelles Passwort eingeben',
      c_password: 'Passwort bestätigen',
      c_newpass: 'Neues  Passwort estätigen',
      email: 'Email eingeben',
      subject: 'Betreff eingeben',
      message: 'Gib deine Nachricht ein',
      filtername: 'Gib einen Namen für den Filter ein'
    },
    errors: {
      language: {
        load: 'Fehler beim Wechsel der Sprache.'
      }
    },
    here: 'Hier',
    ok: 'Ok',
    cancel: 'Abbrechen',
    on: 'An',
    off: 'Aus',
    min: 'Mindestwert',
    max: 'Maximalwert',
    years: '{n} Jahr | {n} Jahre',
    days: '{n} Tag | {n} Tage',
    type_for_more: 'Tippen für mehr...'
  },
  notifications: {
    disabled: 'Die {notifications} werden blockiert. Wenn du diese nicht geblockt hast, versuche einen anderen Browser.',
    notifications: 'Benachrichtigungen',
    title: 'Benachrichtungseinstellungen',
    form: {
      email: 'Email Benachrichtigungen',
      email_frequency: 'Häufigkeit der E-Mail-Benachrichtigungen',
      browser: 'Browser Benachrichtigungen',
      browser_frequency: 'Häufigkeit der Browser-Benachrichtigungen',
      frequency: 'sofort | einmal alle {n} Stunde | einmal alle {n} Stunden',
      help: {
        email: 'Du erhälst Benachrichtigungen zu Spielern per Email.',
        browser: 'Du erhälst Benachrichtigungen zu Spielern in deinem Browser.',
        frequency: 'Der empfohlene Wert ist \'sofort\', um zu vermeiden, dass einige Spieler verpasst werden.',
        blocked: 'Du hast Benachrichtigungen im Browser geblockt oder dein Browser unterstützt dies nicht.'
      }
    }
  },
  header: {
    menu: {
      home: 'Startseite',
      account: 'Konto',
      signin: 'Anmelden',
      register: 'Registrieren',
      inbox: 'Inbox',
      blog: 'Blog',
      contact: 'Kontakt',
      logout: 'Abmelden'
    }
  },
  footer: {
    menu: {
      privacy: 'Privatsphäre (Englische)',
      terms: 'Geschäftsbedingungen (Englische)'
    }
  },
  login: {
    title: 'Anmelden',
    signin: 'Anmelden',
    register: 'Registrieren',
    forgot: 'Passwort vergessen?',
    form: {
      submit: 'Anmelden',
      remember: 'Angemeldet bleiben'
    },
    info: {
      what: 'Was macht Mercattrick?',
      purpose: 'Die Idee von Mercattrick:',
      purpose1: 'hilft Hattrick <strong>Managern</strong> dass der <strong>Verkauf</strong> von Spielern einen größeren Ertrag gibt;',
      purpose2: 'hilft Hattrick <strong>Managern</strong> welche <strong>Interesssiert sind Spieler zu kaufen</strong> Benachrichtigungen zu erhalten wenn interessante Spieler auf der Transferliste sind;',
      how: 'Wie funktioniert es?',
      buyers: 'Käufer',
      step1: 'melde dich an und erstelle ein Set von Mindestanforderungen für Spieler (Filter) für welche du benachrichtigt werden möchtest wenn sie zum Verkauf stehen.',
      sellers: 'Verkäufer',
      step2: 'setz einen Spieler in Hattrick auf die transferliste, melde dich dann hier an und biete den Spieler hier an.',
      promoting: 'Anbieten',
      step3: 'alle interessierten Käufer welche an so einem Spieler interessiert sind werden benachrichtigt sobald der Spieler zum Verkauf steht.'
    }
  },
  register: {
    title: 'Registrieren',
    form: {
      registered: 'Dein Konto wurde erstellt. Klicke {here} um fortzufahren.',
      submit: 'Registrieren',
      agreement: 'Durch klicken auf @:register.form.submit stimmst du den {terms} und {privacy} zu und du stimmst zu, gelegentlich kontobezogene E-Mails zu erhalten.',
      tos: 'Nutzungsbedingungen',
      privacy: 'Datenschutzerklärung',
      help: {
        username: 'Dies ist nicht dein Benutzername von Hattrick.',
        password: 'Bitte gib mindestens 6 Zeichen ein. Bitte beachte Groß- und Kleinschreibung.',
        email: 'Wie verwenden die Email nur um Sie bei neuen Spielern zu kontaktieren.'
      }
    }
  },
  recover: {
    title: 'Passwort wiederherstellen',
    form: {
      recovered: 'Eine Email mit einem Link wurde an deine Email-Adresse gesendet.',
      submit: 'Wiederherstellen',
      help: {
        email: 'Dies muss die Email-Adresse sein welche mit deinem Account verknüpft ist.'
      }
    }
  },
  reset: {
    title: 'Passwort zurücksetzen',
    form: {
      submit: 'Zurücksetzen',
      help: {
        email: 'Dies muss die Email-Adresse sein welche mit deinem Account verknüpft ist.',
        password: 'Bitte gib mindestens 6 Zeichen ein. Bitte beachte Groß- und Kleinschreibung.'
      }
    }
  },
  contact: {
    title: 'Kontakt',
    form: {
      success: 'Die Nachricht wurde erfolgreich versand.',
      submit: 'Senden',
      help: {
        email: 'Wie verwenden die Email nur um Sie zu kontaktieren.'
      }
    }
  },
  sync: {
    users: {
      syncing: 'Synchronisiere Nutzerdaten...',
      fail: 'Synchronisierung fehlgeschlagen.',
      retry: 'Nochmal versuchen',
      authorize: 'Du musst dieses CHPP Produkt authorisieren. Klicke {here} um Hattrick zu öffnen.',
      authorizing: 'Initialisiere die Authorisierung. Du wirst auf <strong>hattrick.org</strong> weitergeleitet ...',
      finalize: 'Beenden der Authorisierung...'
    },
    system: {
      syncing: 'Abgleich des Systems...',
      fail: 'System-Abgleich fehlgeschlagen. {retry} oder {skip}!',
      retry: 'Nochmal versuchen',
      skip: 'Überspringen'
    }
  },
  account: {
    account: {
      title: 'Kontoeinstellungen',
      form: {
        success: 'Dein Konto wurde erfolgreich aktualisiert.',
        submit: 'Speichern',
        help: {
          username: 'Dies ist nicht dein Bnenutzername in Hattrick.',
          email: 'Wir verwenden die Email um dich über neue Spieler zu informieren.'
        }
      }
    },
    login: {
      title: 'Logineinstellungen',
      form: {
        success: 'Das Passwort wurde erfolgreich aktualisiert.',
        submit: 'Speichern',
        help: {
          passwords: 'Gib dein aktuelles Passwort sowie dein neues Passwort ein.'
        }
      }
    },
    privacy: {
      title: 'Datenschutzeinstellungen',
      form: {
        submit: 'Konto löschen',
        delete: 'Löschen',
        help: {
          warning: 'Diese Aktien ist endgültig. All deine Daten werden gelöscht.',
          confirmation: 'Bitte bestätige das du dein Konto löschen möchtest.'
        }
      }
    }
  },
  filter: {
    title: 'Drücken und einen Filter hinzuzufügen/zu löschen',
    form: {
      submit: 'Speichern',
      set: 'Einstellen',
      remove: 'Entfernen',
      help: {
        name: 'Gib einen Namen für den Filter an.'
      }
    },
    tooltip: {
      save: 'Du musst midestens ein Filterkriterium angeben!'
    },
    item: {
      tooltip: 'Klick auf \'@:filter.item.show_players\' um die Liste anzuzeigen.',
      show_players: 'Zeige Spieler',
      hide_players: 'Verstecke Spieler',
      players: 'Spieler gefunden',
      form: {
        processing: 'bearbeitung',
        fetch: 'Rufe Spieler von Hattrick ab',
        edit: 'Ändern',
        delete: 'Löschen',
        help: {
          confirm: 'Bist du sicher dass du den Filter löschen möchtest?'
        }
      }
    }
  },
  home: {
    myplayers: {
      title: 'Meine Transfers',
      loading: 'Lade Spieler...',
      none: 'Du hast keine Spieler zum Verkauf.',
      promoted: 'angeboten',
      late: 'zu spät zum anbieten',
      form: {
        reload: 'Neu laden',
        promote: 'Anbieten'
      },
      tooltip: 'Biete deine Spieler für anderen Manager an.'
    },
    bookmarks: {
      title: 'Favoriten',
      loading: 'Lade Spiler...',
      none: 'Du hast keine Spieler in den Favoriten.',
      form: {
        reload: 'Neu laden',
        unbookmark: 'Entfernen',
        help: {
          confirm: 'Bist du sicher dass du diesen Favoriten löschen möchtest?'
        }
      },
      tooltip: 'Du wirst benachrichtigt kurz bevor die Transferzeit der Spieler abläuft.'
    },
    filters: {
      title: 'Meine Filter',
      loading: 'Lade Filter...',
      none: 'Du hast keine Filter angelegt. Du solltest einige anlegen.',
      form: {
        reload: 'Neu laden',
        add: 'Filter hinzufügen',
        bookmark: 'Merken',
        hide: 'Verstecken',
        help: {
          confirm: 'Bist du sicher dass du diese Spieler ausblenden möchtest?'
        }
      },
      tooltip: 'Merke interessante Spieler von dieser Liste.'
    }
  },

  player: {
    attributes: {
      name: 'Name',
      age: 'Alter',
      form: 'Form',
      experience: 'Erfahrung',
      leadership: 'Führungsqualitäten',
      stamina_skill: 'Kondition',
      keeper_skill: 'Torwart',
      playmaker_skill: 'Spielaufbau',
      scorer_skill: 'Torschuss',
      passing_skill: 'Passspiel',
      winger_skill: 'Flügelspiel',
      defender_skill: 'Verteidigung',
      set_pieces_skill: 'Standards',
      agreeability: 'Beliebtheit',
      aggressiveness: 'Psyche',
      honesty: 'Charakter',
      htms: 'HTMS',
      htms28: 'HTMS28',
      tsi: 'TSI',
      salary: 'Gehalt',
      price: 'Preis',
      asking_price: 'Mindestgebot',
      highest_bid: 'Höchstgebot',
      native_country: 'Heimatland',
      native_country_id: 'Heimatland',
      NT_player: 'Nationalspieler',
      specialties: 'Spezialitäten',
      specialty: 'Spezialität'
    },
    redcard: 'Rote Karte',
    yellowcard: 'Gelbe Karte',
    bruised: 'Angeschlagen',
    injured: 'Verletzt',
    injury: '{n} Woche | {n} Wochen',
    fullage: '{years} Jahre und {days} Tage',
    deadline: 'Deadline',
    htms: 'HTMS/HTMS28',
    more: 'Zeige mehr',
    less: 'Zeige weniger',
    levels: {
      skill: [
        'nicht vorhanden',
        'katastrophal',
        'erbärmlich',
        'armselig',
        'schwach',
        'durchschnittlich',
        'passabel',
        'gut',
        'sehr gut',
        'hervorragend',
        'großartig',
        'brillant',
        'fantastisch',
        'Weltklasse',
        'übernatürlich',
        'gigantisch',
        'außerirdisch',
        'mythisch',
        'märchenhaft',
        'galaktisch',
        'göttlich',
        'göttlich (+1)',
        'göttlich (+2)',
        'göttlich (+3)',
        'göttlich (+4)',
        'göttlich (+5)'
      ],
      agreeability: [
        'ein Ekel',
        'eine umstrittene Person',
        'ein angenehmer Zeitgenosse',
        'ein sympathischer Mensch',
        'ein beliebter Spieler',
        'eine Integrationsfigur'
      ],
      aggressiveness: [
        'introvertiert',
        'ruhig',
        'ausgeglichen',
        'temperamentvoll',
        'aufbrausend',
        'hitzköpfig'
      ],
      honesty: [
        'niederträchtig',
        'unehrlich',
        'ehrlich',
        'aufrichtig',
        'rechtschaffen',
        'herzensgut'
      ],
      specialty: [
        'keine',
        'Ballzauberer',
        'schnell',
        'durchsetzungsstark',
        'unberechenbar',
        'Kopfballstärke',
        'robust',
        '',
        'mannschaftsdienlich'
      ],
      national_team: [
        'niemals',
        'aktuell nein',
        'aktuell ja'
      ],
      any: 'alle/keine'
    },
    tooltips: {
      highest_bid: 'Das Höchstgebot kann veraltet sein!'
    }
  },
  countries: {
    1: 'Schweden',
    2: 'England',
    3: 'Deutschland',
    4: 'Italien',
    5: 'Frankreich',
    6: 'Mexiko',
    7: 'Argentinien',
    8: 'USA',
    9: 'Norwegen',
    10: 'Dänemark',
    11: 'Finnland',
    12: 'Niederlande',
    13: 'Ozeanien',
    14: 'Kanada',
    15: 'Schottland',
    16: 'Irland',
    17: 'Chile',
    18: 'Kolumbien',
    19: 'Uruguay',
    20: 'Venezuela',
    21: 'Peru',
    22: 'Brasilien',
    23: 'Portugal',
    24: 'Südafrika',
    25: 'Japan',
    26: 'Polen',
    27: 'Indien',
    28: 'China',
    29: 'Südkorea',
    30: 'Thailand',
    31: 'Türkei',
    32: 'Ägypten',
    33: 'Österreich',
    34: 'Russland',
    35: 'Spanien',
    36: 'Rumänien',
    37: 'Island',
    38: 'Belgien',
    39: 'Malaysia',
    40: 'Schweiz',
    41: 'Singapur',
    42: 'Kroatien',
    43: 'Serbien',
    44: 'Ungarn',
    45: 'Griechenland',
    46: 'Tschechien',
    47: 'Estland',
    48: 'Lettland',
    49: 'Indonesien',
    50: 'Philippinen',
    51: 'Israel',
    52: 'Taiwan',
    53: 'Hong Kong',
    55: 'Bulgarien',
    56: 'Wales',
    57: 'Slowenien',
    61: 'Litauen',
    62: 'Ukraine',
    63: 'Bosnien und Herzegowina',
    64: 'Pakistan',
    65: 'Vietnam',
    66: 'Slowakei',
    67: 'Paraguay',
    68: 'Ecuador',
    69: 'Bolivien',
    70: 'Nigeria',
    71: 'Faröer Inseln',
    72: 'Marokko',
    75: 'Saudi Arabien',
    76: 'Tunesien',
    77: 'Costa Rica',
    78: 'Vereinigte Arabische Emirate',
    79: 'Luxemburg',
    80: 'Iran',
    82: 'Zypern',
    83: 'Dominikanische Republik',
    86: 'Senegal',
    87: 'Weißrussland',
    88: 'Nordirland',
    89: 'Jamaika',
    90: 'Kenja',
    91: 'Panama',
    92: 'Mazedonien',
    93: 'Kuba',
    94: 'Albanien',
    95: 'Honduras',
    96: 'El Salvador',
    97: 'Malta',
    98: 'Kirgisistan',
    99: 'Moldoawien',
    100: 'Georgien',
    101: 'Andorra',
    102: 'Guatemala',
    103: 'Jordanien',
    104: 'Armenien',
    105: 'Trinidad & Tobago',
    121: 'Nicaragua',
    122: 'Kasachstan',
    123: 'Surinam',
    125: 'Liechtenstein',
    126: 'Algerien',
    127: 'Mongolei',
    128: 'Libanon',
    129: 'Bahrain',
    130: 'Barbados',
    131: 'Kapverden',
    132: 'Elfenbeinküste',
    133: 'Aserbaidschan',
    134: 'Kuwait',
    135: 'Irak',
    136: 'Montenegro',
    137: 'Angola',
    138: 'Bangladesch',
    139: 'Yemen',
    140: 'Oman',
    142: 'Mosambique',
    143: 'Brunei',
    144: 'Ghana',
    145: 'Kambodscha',
    147: 'Benin',
    148: 'Syrien',
    149: 'Katar',
    150: 'Tanzania',
    153: 'Uganda',
    154: 'Malediven',
    163: 'Usbekistan',
    165: 'Kamerun',
    166: 'Palestina',
    175: 'Sri Lanka',
    177: 'São Tomé und Príncipe',
    178: 'Curaçao',
    179: 'Guam',
    180: 'Komoren',
    181: 'DR Kongo',
    182: 'Äthiopien',
    183: 'Madagaskar',
    184: 'St. Vincent und die Grenadinen',
    185: 'Botswana',
    186: 'Belize',
    187: 'Sambia',
    188: 'Haiti',
    189: 'Myanmar',
    190: 'Puerto Rico',
    191: 'San Marino',
    192: 'Nepal',
    193: 'Burkina Faso',
    194: 'Grenada',
    196: 'Tahiti',
    197: 'Guyana'
  },

  validation: {
    attributes: {},
    messages: {
      alpha_num: 'Dieses Feld darf nur alphanumerische Zeichen beinhalten.',
      alpha_dash: 'Dieses Feld darf nur alphanumerische Zeichen, Unterstriche oder Bindestriche enthalten.',
      confirmed: 'Die Bestätigung stimmt nicht überein.',
      email: 'Dieses Feld muss eine valide Email-Adresse beinhalten.',
      max: 'Dieses Feld beinhaltet zu viele Zeichen.',
      min: 'Dieses Feld beinhaltet zu wenig Zeichen.',
      required: 'Dies ist ein Pflichtfeld.'
    }
  },

  api: {
    validation: {
      string: {
        subject: 'Betreff ist ein Pflichtfeld.',
        body: 'Nachricht ist ein Pflichtfeld.',
        username: 'Benutzername ist ein Pflichtfeld.',
        password: 'Passwort ist ein Pflichtfeld.',
        locale: 'Sprache ist ein Pflichtfeld.',
        name: 'Name ist ein Pflichtfeld.',
        token: 'Token ist ein Pflichtfeld.'
      },
      integer: {
        native_country_id: 'Das Land muss eine Nummer sein.',
        htms_min: 'Der minimale HTMS muss eine Nummer sein.',
        htms_max: 'Der maximale HTMS muss eine Nummer sein.',
        htms28_min: 'Der minimale HTMS28 muss eine Nummer sein.',
        htms28_max: 'Der maximale HTMS28 muss eine Nummer sein.',
        tsi_min: 'Der minimale TSI muss eine Nummer sein.',
        tsi_max: 'Der maximale TSI muss eine Nummer sein.'
      },
      numeric: {
        age_min: 'Das mindestalter muss eine Nummer sein.',
        age_max: 'Das maximale Alter muss eine Nummer sein.',
        salary_min: 'Der minimale Gehalt muss eine Nummer sein.',
        salary_max: 'Der maximale Gehalt muss eine Nummer sein.',
        price_min: 'Der kleinste Preis muss eine Nummer sein.',
        price_max: 'Der maximale Preis muss eine Nummer sein.'
      },
      required: {
        subject: 'Betreff ist ein Pflichtfeld.',
        body: 'Nachricht ist ein Pflichtfeld.',
        username: 'Benutzername ist ein Pflichtfeld.',
        password: 'Passwort ist ein Pflichtfeld.',
        current_password: 'aktuelles Passwort ist ein Pflichtfeld.',
        new_password: 'neues Passwort ist ein Pflichtfeld.',
        email: 'Email ist ein Pflichtfeld.',
        token: 'Token ist ein Pflichtfeld.',
        endpoint: 'Der \'endpoint\' Parameter ist ein Pflichtfeld.',
        url: 'Die \'URL\' Parameter ist ein Pflichtfeld.',
        oauth_token: 'Der \'oauth_token\' Parameter ist ein Pflichtfeld.',
        oauth_verifier: 'Der \'oauth_verifier\' Parameter ist ein Pflichtfeld.'
      },
      max: {
        age_min: 'Das Mindestalter darf nicht größer als 100 sein.',
        age_max: 'Das Höchstalter darf nicht größer als 100 sein.',
        htms_min: 'Der minimale HTMS-Wert darf nicht größer als 12.000 sein.',
        htms_max: 'Der maximale HTMS-Wert darf nicht größer als 12.000 sein.',
        htms28_min: 'Der minimale HTMS28-Wert darf nicht größer als 12.000 sein.',
        htms28_max: 'Der maximale HTMS28-Wert darf nicht größer als 12.000 sein.',
        tsi_min: 'Der minimale TSI darf nicht größer als 16.000.000 sein.',
        tsi_max: 'Der maximale TSI darf nicht größer als 16.000.000 sein.',
        subject: 'Betreff hat zuviele Zeichen.',
        username: 'Benutzername hat zuviele Zeichen.',
        email: 'Email hat zuviele Zeichen.',
        locale: 'Sprache hat zuviele Zeichen.',
        name: 'Name hat zuviele Zeichen.',
        token: 'Token hat zuviele Zeichen.',
        NT_player: 'Der Nationalspieler-Wert darf maximal 2 (\'@:player.levels.national_team[2]\').',

        string: {
          subject: '@:api.validation.max.subject',
          username: '@:api.validation.max.username',
          email: '@:api.validation.max.email',
          locale: '@:api.validation.max.locale',
          name: '@:api.validation.max.name',
          token: '@:api.validation.max.token',
          NT_player: '@:api.validation.max.NT_player'
        },

        numeric: {
          age_min: '@:api.validation.max.age_min',
          age_max: '@:api.validation.max.age_max',
          htms_min: '@:api.validation.max.htms_min',
          htms_max: '@:api.validation.max.htms_max',
          htms28_min: '@:api.validation.max.htms28_min',
          htms28_max: '@:api.validation.max.htms28_max',
          tsi_min: '@:api.validation.max.tsi_min',
          tsi_max: '@:api.validation.max.tsi_max',
          NT_player: '@:api.validation.max.NT_player'
        }
      },
      min: {
        password: 'Das Passwort hat zuwenige Zeichen.',
        age_min: 'Das Mindestalter muss größer als 17 sein.',
        age_max: 'Das Höchstalter muss größer als 17 sein.',
        native_country_id: 'Die Country Id muss größer 0 sein.',
        htms_min: 'Der minimale HTMS muss größer 0 sein.',
        htms_max: 'Der maximale HTMS muss größer 0 sein.',
        htms28_min: 'Der minimale HTMS28 muss größer als -12.000 sein.',
        htms28_max: 'Der maximale HTMS28 muss größer als -12.000 sein.',
        tsi_min: 'Der minimale TSI muss größer 0 sein.',
        tsi_max: 'Der maximale TSI muss größer 0 sein.',
        salary_min: 'Das minimale Gehalt muss größer 0 sein.',
        salary_max: 'Das maixmale Gehalt muss größer 0 sein.',
        price_min: 'Der minimale Preis muss größer 0 sein.',
        price_max: 'Der maximale Preis muss größer 0 sein.',
        NT_player: 'Der Nationalspieler-Wert muss mindestens 0 (\'@:player.levels.national_team[0]\').',

        string: {
          password: '@:api.validation.min.password',
          age_min: '@:api.validation.min.age_min',
          age_max: '@:api.validation.min.age_max',
          native_country_id: '@:api.validation.min.native_country_id',
          htms_min: '@:api.validation.min.htms_min',
          htms_max: '@:api.validation.min.htms_max',
          htms28_min: '@:api.validation.min.htms28_min',
          htms28_max: '@:api.validation.min.htms28_max',
          tsi_min: '@:api.validation.min.tsi_min',
          tsi_max: '@:api.validation.min.tsi_max',
          salary_min: '@:api.validation.min.salary_min',
          salary_max: '@:api.validation.min.salary_max',
          price_min: '@:api.validation.min.price_min',
          price_max: '@:api.validation.min.price_max',
          NT_player: '@:api.validation.min.NT_player'
        },

        numeric: {
          age_min: '@:api.validation.min.age_min',
          age_max: '@:api.validation.min.age_max',
          native_country_id: '@:api.validation.min.native_country_id',
          htms_min: '@:api.validation.min.htms_min',
          htms_max: '@:api.validation.min.htms_max',
          htms28_min: '@:api.validation.min.htms28_min',
          htms28_max: '@:api.validation.min.htms28_max',
          tsi_min: '@:api.validation.min.tsi_min',
          tsi_max: '@:api.validation.min.tsi_max',
          salary_min: '@:api.validation.min.salary_min',
          salary_max: '@:api.validation.min.salary_max',
          price_min: '@:api.validation.min.price_min',
          price_max: '@:api.validation.min.price_max',
          NT_player: '@:api.validation.min.NT_player'
        }
      },
      email: {
        email: 'Das Email-Feld muss eine valide Email-Adresse beinhalten.'
      },
      url: {
        url: 'Der \'URL\' Parameter muss eine valide URL sein.'
      },
      in: {
        endpoint: 'Der \'endpoint\' Parameter muss gültig sein.',
        form_min: 'Die minimale Form muss zwischen 1 (\'@:player.levels.skill[1]\') und 8 (\'@:player.levels.skill[8]\').',
        form_max: 'Die maximale Form muss zwischen 1 (\'@:player.levels.skill[1]\') und 8 (\'@:player.levels.skill[8]\').',
        agreeability_min: 'Der minimale Beliebtheitswert muss zwischen 0 (\'@:player.levels.agreeability[0]\') und 5 (\'@:player.levels.agreeability[5]\').',
        agreeability_max: 'Der maximale Beliebtheitswert muss zwischen 0 (\'@:player.levels.agreeability[0]\') und 5 (\'@:player.levels.agreeability[5]\').',
        aggressiveness_min: 'Der minimale Psychewert muss zwischen 0 (\'@:player.levels.aggressiveness[0]\') und 5 (\'@:player.levels.aggressiveness[5]\').',
        aggressiveness_max: 'Der maximale Psychewert muss zwischen 0 (\'@:player.levels.aggressiveness[0]\') und 5 (\'@:player.levels.aggressiveness[5]\').',
        honesty_min: 'Der minimale Charakterwert muss zwischen 0 (\'@:player.levels.honesty[0]\') und 5 (\'@:player.levels.honesty[5]\').',
        honesty_max: 'Der maximale Charakterwert muss zwischen 0 (\'@:player.levels.honesty[0]\') und 5 (\'@:player.levels.honesty[5]\').',
        experience_min: 'Die minimale Erfahrung muss zwischen 0 (\'@:player.levels.skill[0]\') und 25 (\'@:player.levels.skill[25]\').',
        experience_max: 'Die maximale Erfahrung muss zwischen 0 (\'@:player.levels.skill[0]\') und 25 (\'@:player.levels.skill[25]\').',
        leadership_min: 'Die minimalen Führungsqualitäten muss zwischen 1 (\'@:player.levels.skill[1]\') und 8 (\'@:player.levels.skill[8]\').',
        leadership_max: 'Die maximalen Führungsqualitäten muss zwischen 1 (\'@:player.levels.skill[1]\') und 8 (\'@:player.levels.skill[8]\').',
        stamina_skill_min: 'Die minimale Kondition muss zwischen 0 (\'@:player.levels.skill[0]\') und 9 (\'@:player.levels.skill[9]\').',
        stamina_skill_max: 'Die maximale Kondition muss zwischen 0 (\'@:player.levels.skill[0]\') und 9 (\'@:player.levels.skill[9]\').',
        keeper_skill_min: 'Der minimale Torwartwert muss zwischen 0 (\'@:player.levels.skill[0]\') und 25 (\'@:player.levels.skill[25]\').',
        keeper_skill_max: 'Der maximale Torwartwert muss zwischen 0 (\'@:player.levels.skill[0]\') und 25 (\'@:player.levels.skill[25]\').',
        playmaker_skill_min: 'Der minimale Spielaufbauwert muss zwischen 0 (\'@:player.levels.skill[0]\') und 25 (\'@:player.levels.skill[25]\').',
        playmaker_skill_max: 'Der maximale Spielaufbauwert muss zwischen 0 (\'@:player.levels.skill[0]\') und 25 (\'@:player.levels.skill[25]\').',
        scorer_skill_min: 'Der minimale Torschusswert muss zwischen 0 (\'@:player.levels.skill[0]\') und 25 (\'@:player.levels.skill[25]\').',
        scorer_skill_max: 'Der maximale Torschusswert muss zwischen 0 (\'@:player.levels.skill[0]\') und 25 (\'@:player.levels.skill[25]\').',
        passing_skill_min: 'Der minimale Passspielwert muss zwischen 0 (\'@:player.levels.skill[0]\') und 25 (\'@:player.levels.skill[25]\').',
        passing_skill_max: 'Der maximale Passspielwert muss zwischen 0 (\'@:player.levels.skill[0]\') und 25 (\'@:player.levels.skill[25]\').',
        winger_skill_min: 'Der minimale Flügelwert muss zwischen 0 (\'@:player.levels.skill[0]\') und 25 (\'@:player.levels.skill[25]\').',
        winger_skill_max: 'Der maximale Flügelwert muss zwischen 0 (\'@:player.levels.skill[0]\') und 25 (\'@:player.levels.skill[25]\').',
        defender_skill_min: 'Der minimale Verteidigungswert muss zwischen 0 (\'@:player.levels.skill[0]\') und 25 (\'@:player.levels.skill[25]\').',
        defender_skill_max: 'Der maximale Flügelwert muss zwischen 0 (\'@:player.levels.skill[0]\') und 25 (\'@:player.levels.skill[25]\').',
        set_pieces_skill_min: 'Der minimale Standardsituationenwert muss zwischen 0 (\'@:player.levels.skill[0]\') und 25 (\'@:player.levels.skill[25]\').',
        set_pieces_skill_max: 'Der maximale Standardsituationenwert muss zwischen 0 (\'@:player.levels.skill[0]\') und 25 (\'@:player.levels.skill[25]\').'
      },
      exists: {
        user_id: 'Der Benutzer existiert nicht mehr.'
      },
      boolean: {
        promoted: 'Der \'promoted\' Parameter muss true oder false sein.',
        bookmarked: 'Der \'bookmarked\' Parameter muss true oder false sein.',
        ignored: 'Der \'ignored\' Parameter muss true oder false sein.',
        notifications_email: 'Der \'notifications_email\' Pparameter muss true oder false sein.',
        notifications_desktop: 'Der \'notifications_desktop\' Parameter muss false sein.'
      },
      array: {
        specialties: 'Die Spezialitäten müssen eine Reihe von Werten enthalten.'
      }
    },
    unknown: {
      general: 'Ein unerwarteter Fehler ist aufgetreten. Wir empfehlen, die Seite zu aktualisieren.'
    },
    network: {
      general: 'Es gibt Probleme mit der Netzwerkverbindung.',
      maintenance: 'Systemwartung. Bitte versuchen Sie es in ein paar Minuten wieder.'
    },
    denied: {
      access: 'Zugriff verweigert.'
    },
    expired: {
      token: 'Authentifizierungstoken ist abgelaufen.',
      session: 'Der Benutzer ist nicht authentifiziert.'
    },
    failed: {
      registration: 'Registrierung kann nicht abgeschlossen werden.',
      user_details: 'Abfrage der Benutzerdaten nicht möglich.',
      player_details: 'Abfrage der Spielerdetails nicht möglich.',
      filter_fetch: 'Abfrage der Spieler nicht möglich.',
      password_update: 'Passwort wurde nicht geändert.',
      chpp_connection: 'Verbindung zu CHPP nicht möglich.',
      chpp_authorization: 'CHPP Autorisierung nicht möglich.'
    },
    invalid: {
      credentials: 'ungültige Zugangsdaten.',
      token: 'Authentifizierungstoken ist ungültig.',
      request: 'Ungültige Anfrage.'
    },
    limit: {
      filters: 'Du hast dii maximale Anzahl an Filtern erreicht.'
    },
    missing: {
      token: 'Authentifizierungstoken fehlt.'
    },
    conflict: {
      username: 'Benutzername bereits vergeben.',
      username_password: 'Benutzername und/oder Email bereits vergeben.'
    }
  }
}
